import React from 'react'
import Button from '../../../../common/Button'
import { headerStyle, headerTextStyle } from '../assets/styles/general'

const Header = ({ title, buttonLabel, buttonAction, testId }) => (
  <div id="content-header" style={headerStyle}>
    <h2 style={headerTextStyle}>{title}</h2>
    {buttonAction ? <Button testId={testId} label={buttonLabel} onClick={buttonAction} /> : null}
  </div>
)

export default Header
